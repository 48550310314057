<template>
  <div>
    <el-row
      v-loading="searching"
      :gutter="20"
    >
      <el-pagination
        class="pagination"
        :current-page.sync="currentPage"
        layout="sizes, prev, pager, next, total, jumper"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="(hits <= 10000 ? hits : 10000)"
        @size-change="pageSizeChange"
      />
      <el-switch
        v-model="refineBySelection"
        active-text="Refine by Selection"
        @change="selectionSwitchChange"
      />
      <selection-count
        :selected-items="selectedItems"
        :label="['$name', ' - ', '$city', ', ', '$state', ' ', '$zip']"
        class="selections"
      />
      <el-button
        v-if="selectedItems.length > 1"
        class="selections"
        type="small"
        @click="clearSelections()"
      >
        Clear Selections
      </el-button>
      <el-col
        :xl="4"
        :md="6"
        :xs="24"
      >
        <el-steps :active="activeStep">
          <el-step
            v-for="(step, index) of steps"
            :key="index"
            :title="step.title"
            :description="step.description"
            :status="step.status"
          />
        </el-steps>

        <div v-if="activeStep === 1">
          <label>
            Search by:
            <el-select v-model="msIndex">
              <el-option
                value="neustar"
                label="Business Name / Category"
              />
              <el-option
                value="chainstore"
                label="Brand Name / Category"
              />
            </el-select>
          </label>
          <help-tip
            v-if="msIndex === 'neustar'"
            class="help"
          >
            <h4>Business Name / Category</h4>
            <p>Best used for small to medium size businesses or non-regional or national brands.</p>
            <p>Example name search terms: Chinese restaurant, brewery, chiropractor, yoga</p>
            <p>Example category search terms: Automotive repair, book stores, pet grooming, window cleaning</p>
          </help-tip>

          <help-tip
            v-if="msIndex === 'chainstore'"
            class="help"
          >
            <h4>Brand Name / Category</h4>
            <p>Best used for regional or national brand name businesses.</p>
            <p>Example name search terms: 7 Eleven, Ace Hardware, McDonalds, Walmart</p>
            <p>Example category search terms: Cannabis, convenience stores, department stores, supermarkets.</p>
          </help-tip>

          <el-input
            v-model="query"
            placeholder="Search by name or category"
            @keyup.native.enter="runSearch"
            @keyup.native.esc="query = ''"
          />

          <el-button
            class="inline"
            :loading="searching"
            @click="runSearch"
          >
            Search
          </el-button>
          <help-tip class="help">
            <h4>Search tips</h4>
            <p>
              Search will automatically account for typos. In some cases you may have<br>
              better results by enclosing your search term with quotes - for example, "A&W"
            </p>
          </help-tip>
          <hr v-if="data.length || activeSegments.length">
          <h5 v-if="data.length || activeSegments.length">
            Refine by:
          </h5>
          <el-collapse
            v-if="data.length || activeSegments.length"
            v-model="activeSegments"
          >
            <el-collapse-item
              v-for="(section, sectionIndex) of searchSections"
              :key="sectionIndex"
              :name="section.name"
              :title="section.title"
            >
              <div class="applied">
                <el-tag
                  v-for="tag of filters[section.name]"
                  :key="tag"
                  type="primary"
                  closable
                  @close="removeFilter(tag, section.name)"
                >
                  {{ tag }}
                </el-tag>
              </div>

              <div
                v-for="(item, index) of section.data.slice(0, section.limit)"
                v-show="!activeFilter(item.value, section.name) && data.length"
                :key="section.name + '-' + index"
                class="filter-item"
                @click="setFilter(item.value, section.name)"
              >
                <span class="grow filter-child">{{ item.value }}</span>
                <span class="filter-child">{{ item.count }}</span>
              </div>

              <el-button
                v-if="section.data.length > section.limit"
                plain
                size="mini"
                type="secondary"
                @click="section.limit += 10"
              >
                Show More
              </el-button>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div v-if="activeStep === 2">
          <div>
            <div
              v-if="msIndex !== 'neustar'"
              style="margin-bottom:1em;"
            >
              <label style="margin-right:.5em;">Countries</label>
              <el-select
                v-model="filters.country"
                filterable
                multiple
                @change="statesChanged"
              >
                <el-option
                  v-for="country in $options.countries"
                  :key="country.abbreviation"
                  :value="country.abbreviation"
                  :label="country.name"
                />
              </el-select>
            </div>

            <label style="margin-right:.5em;">States</label>
            <el-select
              v-model="filters.state"
              filterable
              multiple
              @change="statesChanged"
            >
              <el-option
                v-for="state in filteredStates"
                :key="state.abbreviation"
                :value="state.abbreviation"
                :label="state.name"
              />
            </el-select>

            <el-collapse v-model="activeSegments">
              <el-collapse-item
                v-for="(section, sectionIndex) of targetSections"
                v-show="section.data.length > 1"
                :key="sectionIndex"
                :name="section.name"
                :title="section.title"
              >
                <div class="applied">
                  <el-tag
                    v-for="tag of filters[section.name]"
                    :key="tag"
                    type="primary"
                    closable
                    @close="removeFilter(tag, section.name)"
                  >
                    {{ tag }}
                  </el-tag>
                </div>

                <div
                  v-for="(item, index) of section.data.slice(0, section.limit)"
                  v-show="!activeFilter(item.value, section.name) && data.length"
                  :key="section.name + '-' + index"
                  class="filter-item"
                  @click="setFilter(item.value, section.name)"
                >
                  <span class="grow filter-child">{{ item.value }}</span>
                  <span class="filter-child">{{ item.count }}</span>
                </div>

                <el-button
                  v-if="section.data.length > section.limit"
                  plain
                  size="mini"
                  type="secondary"
                  @click="section.limit += 10"
                >
                  Show More
                </el-button>
              </el-collapse-item>
            </el-collapse>
          </div>

          <div class="form-control">
            <label>
              Enter a list of zipcodes
              <span
                v-if="filters.zip.length > 0"
                class="count"
              >
                {{ filters.zip.length | numeral }} / 2,500
              </span><br>
              <small v-if="msIndex !== 'neustar'">(5 digit US, 3 character Canadian)</small>
              <small v-else>(5 digit, ie: 11001)</small>
            </label>
            <el-input
              v-model="zipcodes"
              type="textarea"
              :rows="10"
              style="margin-top:.5em;"
            />
            <p
              class="small"
            >
              A hard limit of 2,500 zipcodes is enforced, if you need more, you will need to create multiple audiences.
            </p>
          </div>
        </div>

        <div v-if="activeStep === 3">
          <div class="form-control">
            <label>Audience Title</label>
            <el-input v-model="audience.title" />
            <dedupe-control v-model="dedupe" />
          </div>
          <div class="form-control">
            <label>Organization</label>
            <org-picker
              :historical-only="historicalSelection"
              :organization.sync="audience.organization"
            />
          </div>
          <div class="form-control">
            <label>Date Range</label>
            <date-picker
              :date-range.sync="dateRange"
              :organization="audience.organization"
            />
          </div>

          <tag-picker :tags.sync="audience.tags" />
        </div>

        <div>
          <el-button
            v-if="activeStep > 1"
            size="small"
            type="info"
            :disabled="disabled('prev')"
            @click="previousStep()"
          >
            &laquo; {{ stepLabel('prev') }}
          </el-button>
          <el-button
            :loading="saving"
            size="small"
            type="primary"
            :disabled="disabled('next')"
            @click="nextStep()"
          >
            {{ stepLabel('next') }} &raquo;
          </el-button>
        </div>
        <div>
          <el-button
            class="reset-button"
            size="small"
            type="text"
            @click="reset()"
          >
            Reset
          </el-button>
          <el-button
            v-show="activeStep === 3 && previewAvailable"
            size="small"
            type="text"
            @click="preview()"
          >
            Preview
          </el-button>
        </div>
      </el-col>
      <el-col
        :xl="20"
        :md="18"
        :xs="24"
      >
        <el-table
          ref="matchesTable"
          :data="data"
          :default-sort="{prop: 'name', order: 'ascending'}"
          :highlight-current-row="true"
          row-key="id"
          size="small"
          stripe
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            v-if="refineBySelection"
            reserve-selection
            type="selection"
            width="55"
          />
          <el-table-column
            prop="name"
            label="Business Name"
            sortable
          />

          <el-table-column
            v-if="isInternal"
            prop="address"
            label="Street Address"
            sortable
          />
          <el-table-column
            prop="city"
            label="City"
            sortable
          />
          <el-table-column
            prop="state"
            label="State"
            :width="92"
            sortable
          />
          <el-table-column
            prop="zip"
            label="Zip"
            :width="120"
            sortable
          >
            <template slot-scope="{ row }">
              <span v-if="row.country === 'US'">
                {{ row.zip }}{{ row.plus4 ? `-${row.plus4}` : '' }}
              </span>
              <span v-else>
                {{ row.zip }} {{ row.plus4 }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="country"
            label="Country"
            :width="92"
            sortable
          />
          <el-table-column
            prop="category"
            label="Category"
            sortable
          />
        </el-table>
      </el-col>
      <el-pagination
        class="pagination"
        :current-page.sync="currentPage"
        layout="sizes, prev, pager, next, total, jumper"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="(hits <= 10000 ? hits : 10000)"
        @size-change="pageSizeChange"
      />
    </el-row>

    <div v-if="showPreview">
      <el-dialog
        title="Audience Preview"
        size="small"
        :visible="showPreview"
        :before-close="() => { showPreview = false }"
      >
        <h3>Previewing {{ previewData.length | numeral }} of {{ hits | numeral }} Locations</h3>
        <h4
          v-if="hits > 10000"
        >
          You have {{ hits | numeral }} matches, only the first 10,000 will be included in this audience.
        </h4>

        <div>
          <strong>Date Range:</strong>
          {{ dateRange[0] | dateFormat }} - {{ dateRange[1] | dateFormat }}
        </div>

        <el-table :data="previewData">
          <el-table-column
            prop="name"
            label="Business Name"
          />
          <el-table-column
            v-if="isInternal"
            prop="address"
            label="Location"
          />
          <el-table-column
            v-else
            prop="cityState"
            label="Location"
          />
          <el-table-column
            prop="count"
            label="Device Count"
          >
            <template slot-scope="{ row }">
              <span v-if="row.error">
                <i class="fa fa-warning" />
              </span>
              <span v-else-if="row.countPending">
                <i class="fa fa-sync fa-spin" />
              </span>
              <span v-else>
                {{ row.count | numeral }}
              </span>
            </template>
          </el-table-column>
        </el-table>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <span class="left">
            <el-button @click="showPreview = false; activeStep = 3">
              Change Radius
            </el-button>
            <el-button @click="showPreview = false; activeStep = 3">
              Change Date Range
            </el-button>
          </span>
          Close preview to name, tag and create your audience.
          <el-button
            type="primary"
            @click="showPreview = false"
          >
            Close Preview
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  dateFormat,
  dateToString,
} from '../../../helpers';
import { audience as audienceApi } from '@/adonis-api';
import { getValidationErrors } from '@/helpers/validation-rules';
import jobWatcher from '@/mixins/job-watcher';
import Meili from '@/meilisearch';

import moment from 'moment';
import DatePicker from '../../global/DatePicker.vue';
import HelpTip from '../../global/HelpTip.vue';
import OrgPicker from '../../global/OrgPicker.vue';
import TagPicker from '../../global/TagPicker.vue';
import DedupeControl from '../../global/DedupeControl.vue';
import SelectionCount from '../../global/SelectionCount.vue';

// import _omit from 'lodash/omit';
import _take from 'lodash/take';
import _uniq from 'lodash/uniq';

export default {
  countries: [{ name: 'Canada', abbreviation: 'CA' }, { name: 'United States', abbreviation: 'US' }],
  states: require('./states.json'),
  components: {
    DatePicker,
    HelpTip,
    OrgPicker,
    TagPicker,
    DedupeControl,
    SelectionCount,
  },

  filters: {
    dateFormat(value) {
      return dateFormat({
        date: value,
        formatOverride: 'M/D/YYYY',
      });
    },
  },
  mixins: [jobWatcher],

  data() {
    return {
      activeSegments: [],
      activeStep: 1,
      audience: {
        organization: this.$store.state.user.orgDetails,
        tags: [],
        title: null,
      },
      msIndex: 'neustar',
      currentPage: 1,
      data: [],
      dateRange: [],
      dedupe: this.$store.getters['user/autoDedupe'],
      filters: {
        name: [],
        category: [],
        city: [],
        state: [],
        zip: [],
        country: [],
      },
      hits: 0,
      messageShown: false,
      pageSize: this.$store.state.settings.options['pagination:pageSize'],
      previewData: [],
      query: '',
      previousQuery: '',
      searching: false,
      radius: 1,
      radiusFeet: true,
      radiusOptions: [
        { feet: 90, meters: 30 },
        { feet: 165, meters: 50 },
        { feet: 245, meters: 75 },
        { feet: 325, meters: 100 },
        { feet: 650, meters: 200 },
      ],
      refineBySelection: false,
      resetSections: false,
      saving: false,
      sections: [
        {
          name: 'name',
          title: 'Business Name',
          limit: 10,
          data: [],
        },
        {
          name: 'category',
          title: 'Category',
          limit: 10,
          data: [],
        },
        {
          name: 'city',
          title: 'Cities',
          limit: 10,
          data: [],
        },
      ],
      selectedItems: [],
      showPreview: false,
      sort: {
        field: 'name',
        order: 'asc',
      },
      steps: [
        {
          title: 'Search',
          description: '',
          status: 'finish',
        },
        {
          title: 'Target',
          description: '',
          status: 'wait',
        },
        {
          title: 'Finish',
          description: '',
          status: 'wait',
        },
      ],
      zipcodes: '',
    };
  },

  computed: {
    ...mapGetters('settings', ['pageSizes', 'rangeStart']),
    ...mapGetters('mqtt', ['getJobs']),
    ...mapGetters('user', ['hasPermission', 'isInternal']),

    canSearch() {
      return !!this.query ||
             this.filters.business.length > 0 ||
             this.filters.category.length > 0;
    },

    enteredZipCodes() {
      return this.zipcodes.match(/(([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])|\d{5})/gi) || [];
    },

    filteredStates() {
      if (this.msIndex === 'neustar') {
        return this.$options.states.filter(i => i.country === 'US');
      }
      if (this.filters.country.length) {
        return this.$options.states.filter(i => this.filters.country.includes(i.country));
      }
      return this.$options.states;
    },

    historicalAccess() {
      return (
        this.hasPermission('historical_access') &&
        this.audience.organization.historicalAccess
      );
    },

    historicalSelection() {
      return moment(this.dateRange[0]).isBefore(
        this.rangeStart.clone().startOf('day'),
      );
    },

    previewAvailable() {
      return false; // !this.historicalSelection && this.dateRange.length === 2;
    },

    // dateStart() {
    //   const start = new Date();
    //   start.setTime(start.getTime() - 90 /* days */ * 24 * 3600 * 1000);
    //   return start;
    // },

    searchSections() {
      return this.sections.filter(
        item =>
          (['name', 'category'].includes(item.name)) &&
          (item.data.length || this.filters[item.name].length),
      );
    },

    targetSections() {
      return this.sections.filter(item => item.name === 'city');
    },
  },

  watch: {
    watchedJobs: {
      handler(jobs) {
        const completedJobs = jobs.filter(
          job => job.payload.status === 'COMPLETE',
        );

        if (!completedJobs.length) return;

        for (const { jobId, count } of completedJobs.map(job => ({
          count: job.payload.result.count,
          jobId: job.id,
        }))) {
          const preview = this.previewData.find(p => p.jobId === jobId);

          if (preview) {
            preview.countPending = false;
            preview.count = count;
          }
        }
      },
      deep: true,
    },
    activeStep(step) {
      if (step === 2) {
        this.getTargetCities();
      }
    },
    currentPage(val) {
      // rerun search on page change.
      this.search();
    },
    enteredZipCodes(val) {
      const oldCount = this.filters.zip.length;
      if (val.length === 0) {
        this.filters.zip = [];
      }

      if (val) {
        if (Array.isArray(val)) {
          val = val.map(i => i.toUpperCase());
          if (_uniq(val).length > 2500) {
            this.$notify({
              title: 'Warning',
              message: 'Your input has been limited to 2,500.',
              type: 'warning',
            });
            this.filters.zip = _take(_uniq(val), 2500);
          } else {
            this.filters.zip = _uniq(val);
          }

          if (val.length !== oldCount) {
            this.zipcodes = val.join(', ');
          }
        } else {
          this.filters.zip = [];
        }
      } else {
        this.filters.zip = [];
      }
      if (this.filters.zip.length !== oldCount) {
        this.search();
        this.getTargetCities();
      }
    },
    msIndex(val, old) {
      if (val !== old) {
        this.previousQuery = '';
        Object.keys(this.filters).forEach((filter) => {
          this.filters[filter] = [];
        });

        for (const section of this.sections) {
          section.data = [];
          section.limit = 10;
        }
        this.search();
      }
    },
  },

  methods: {
    activeFilter(item, sectionName) {
      return this.filters[sectionName].includes(item);
    },

    clearSelections() {
      this.$refs.matchesTable.clearSelection();
    },

    createAudience() {
      this.saving = true;

      const h = this.$createElement;
      this.$msgbox({
        title: 'Create Audience',
        message: h('div', null, [
          h(
            'p',
            null,
            `You are about to create an audience with ${(
              this.selectedItems.length > 0 ? this.selectedItems.length : this.hits <= 10000 ? this.hits : 10000
            ).toLocaleString()} locations.`,
          ),
          h('p', null, 'Are you sure?'),
        ]),
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      })
        .then(async () => {
          let ids = [];
          let specific = false;
          if (this.selectedItems.length > 0) {
            ids = this.selectedItems.map(i => i.id);
            specific = true;
          } else {
            const items = await Meili.search({
              query: this.query,
              index: this.msIndex,
              page: 1,
              perPage: 10000,
              facets: [],
              filters: this.filters,
            });

            ids = items.hits.map(i => i.id);
          }

          const response = await audienceApi.savePoiAudience({
            name: this.audience.title,
            auto_dedupe: this.dedupe,
            start_date: dateToString(this.dateRange[0]),
            end_date: dateToString(this.dateRange[1]),
            historical: this.historicalSelection,
            search_criteria: {
              ids,
              index: this.msIndex,
              specific,
              search: this.query,
              filters: this.filters,
            },
            organization_id: this.audience.organization.id,
            radius: this.radiusOptions[this.radius].meters,
            tags: this.audience.tags,
          });

          const { audience, job } = response.data;
          window.mixpanel.track('Create QS Audience', {
            ID: audience.id,
            Name: audience.name,
            'Job ID': job.id,
            Geoframes: audience.geo_count,
            Radius: audience.meta.radius,
            'Start Date': audience.start_date,
            'End Date': audience.end_date,
          });
          this.$notify({
            iconClass: 'fa fa-clock',
            message: 'Your audience is being processed.',
          });
          this.reset();
          this.watchJob(job.id);
          this.$router.push('/audiences/library/list');
        })
        .catch(err => {
          if (err !== 'cancel') {
            const errors = getValidationErrors(err);

            if (errors.name) {
              this.$notify({
                message: getValidationErrors(err).name,
                title: 'Error',
                type: 'error',
              });
            } else {
              this.$reportError(err);
            }
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },

    disabled(direction) {
      switch (direction) {
        case 'prev':
          if (this.activeStep === 1) return true;
          break;
        case 'next':
          if (this.activeStep === 1 && !this.data.length) return true;
          if (
            this.activeStep === 3 &&
            (!this.dateRange[0] || !this.dateRange[1])
          ) {
            return true;
          }
          if (this.activeStep === this.steps.length) {
            if (
              !this.audience.title ||
              !this.audience.organization.id ||
              this.hits < 1
            ) {
              return true;
            }
          }
          break;
        default:
          break;
      }
      return false;
    },

    async getTargetCities() {
      const filters = {
        name: this.filters.name,
        category: this.filters.category,
        state: this.filters.state,
        zip: this.filters.zip,
        country: this.filters.country,
      };
      const data = await Meili.search({
        query: this.query,
        index: this.msIndex,
        filters,
        facets: ['city'],
        page: 1,
        perPage: 1,
      });

      Object.keys(data.facetDistribution).forEach(section => {
        this.sections.find(s => s.name === section).data = Object.keys(data.facetDistribution[section]).map(facet => {
          return {
            value: facet,
            count: data.facetDistribution[section][facet],
          };
        });
      });
    },

    handleSelectionChange(selections) {
      this.selectedItems = selections;
      this.$emit('selection-change', selections);
    },

    async preview() {
      this.showPreview = true;

      // Prep preview data:
      let i = 1;
      const promises = [];
      this.previewData = [];
      this.showPreview = true;

      for (const item of this.data) {
        promises.push(
          audienceApi
            .getPoiCount({
              index: this.msIndex,
              id: item.id,
              name: item.name,
              start_date: dateToString(this.dateRange[0]),
              end_date: dateToString(this.dateRange[1]),
              address: `${item.street_address}, ${item.city}, ${item.state} ${item.zip}`,
              radius: this.radiusOptions[this.radius].meters,
            })
            .then(result => {
              this.previewData.push({
                name: item.name,
                address: `${item.street_address}, ${item.city}, ${item.state} ${item.zip}`,
                count: 0,
                error: !result.success,
                countPending: result.success,
                jobId: result.data.id,
              });

              return result.data.id;
            }),
        );
        i++;
        if (i > 5 || i > this.hits) break;
      }

      const jobIds = await Promise.all(promises);

      this.setWatchedJobs(jobIds);
    },

    nextStep() {
      if (this.activeStep === this.steps.length) {
        this.steps[this.activeStep - 1].status = 'success';
        this.createAudience();
      } else {
        this.steps[this.activeStep - 1].status = 'success';
        this.activeStep++;
        this.steps[this.activeStep - 1].status = 'finish';
        this.autoComplete = '';
      }
    },
    previousStep() {
      this.steps[this.activeStep - 1].status = 'process';
      this.activeStep--;
      this.steps[this.activeStep - 1].status = 'finish';
      this.autoComplete = '';
    },

    pageSizeChange(newSize) {
      this.pageSize = newSize;
      this.search();
    },
    removeFilter(item, sectionName) {
      const idx = this.filters[sectionName].indexOf(item);
      if (idx >= 0) {
        this.filters[sectionName].splice(idx, 1);
      }
      this.search();
    },

    reset() {
      this.activeSegments = [];
      this.activeStep = 1;
      this.audience = {
        organization: this.$store.state.user.orgDetails,
        tags: [],
        title: null,
      };
      this.msIndex = 'neustar';
      this.currentPage = 1;
      this.data = [];
      this.dateRange = [];
      this.radius = 1;
      this.radiusFeet = true;
      this.filters = {
        name: [],
        category: [],
        city: [],
        state: [],
        zip: [],
        country: [],
      };
      this.hits = 0;
      this.messageShown = false;
      this.query = '';
      this.previousQuery = '';
      this.searching = false;
      this.selectedItems = [];
      this.sort = {
        field: 'name',
        order: 'asc',
      };
      this.zipcodes = '';

      for (const section of this.sections) {
        section.data = [];
        section.limit = 10;
      }

      for (const step of this.steps) {
        if (step.title === 'Search') {
          step.status = 'finish';
        } else {
          step.status = 'wait';
        }
      }
    },

    runSearch() {
      if (this.canSearch) {
        this.search();
      }
    },

    async search() {
      if (!this.query) return;
      this.searching = true;

      try {
        const data = await Meili.search({
          query: this.query,
          index: this.msIndex,
          page: this.currentPage,
          perPage: this.pageSize,
          sort: this.sort.field,
          direction: this.sort.order,
          filters: this.filters,
        });

        this.hits = data.estimatedTotalHits;

        if (this.previousQuery !== this.query) {
          for (const section of this.sections) {
            section.limit = 10;
          }
          Object.keys(data.facetDistribution).forEach(section => {
            this.sections.find(s => s.name === section).data = Object.keys(data.facetDistribution[section]).map(facet => {
              return {
                value: facet,
                count: data.facetDistribution[section][facet],
              };
            });
          });
        }

        this.previousQuery = this.query;

        if (data.estimatedTotalHits > 10000 && !this.messageShown) {
          this.messageShown = true;
          this.$alert(
            'Only the first 10,000 results will be accessible to you. Please consider refining your search',
            'Too Many Results',
          );
        }

        this.data = data.hits;
      } catch (e) {
        this.$reportError(e);
      } finally {
        this.searching = false;
      }
    },

    selectionSwitchChange(value) {
      if (!value) {
        this.clearSelections();
      }
    },

    setFilter(item, sectionName) {
      this.filters[sectionName].push(item);
      this.runSearch();
    },

    sortChange({ column, prop, order }) {
      this.sort = {
        field: prop,
        order: order === 'ascending' ? 'asc' : 'desc',
      };
      this.search();
    },
    statesChanged() {
      this.search();
      this.getTargetCities();
    },
    stepLabel(direction) {
      if (direction === 'prev') {
        if (this.activeStep === 2) {
          return 'Refine Search';
        } else if (this.activeStep === 3) {
          return 'Refine Target Area';
        }
      } else {
        if (this.activeStep === 1) {
          return 'Define Target Area';
        } else if (this.activeStep === 2) {
          return 'Define Dates';
        }

        return 'Create Audience';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.applied {
  margin-bottom: 1em;
}
.dialog-footer .left {
  float: left;
}
label span.count {
  float: right !important;
}
p.small {
  font-size: 80% !important;
}
:deep( .tags),
.el-collapse,
.form-control {
  margin-top: 1em;
}
.el-tag {
  margin-right: .25em;
  font-size: 0.8em;
  // display: block;
  // margin: 1em .25em;
  width: max-content;
  // margin-left: 0;
  // margin-bottom: 0.25em;
  // cursor: pointer;
}
.el-input {
  display: inline-block;
  max-width: 60%;
}
.el-switch {
  margin-left: 1em;
}
.el-button {
  margin-top: 1em;
  &.inline {
    display: inline-block;
  }
}
.el-table .cell {
  font-size: 0.8em;
}

.help {
  display: inline-block;
  margin: 10px;
}

.filter-item {
  display: flex;
  cursor: pointer;
  .grow {
    flex-grow: 1;
    font-weight: 400;
  }
  &:hover {
    span {
      background: rgb(236, 245, 255);
      color: rgb(64, 158, 255);
    }
  }
}

.pagination {
  clear: both;
  float: right;
  &:first-child {
    margin-top: 0.7em;
    margin-bottom: 1em;
  }
  &:last-child {
    margin-top: 1em;
  }
}

.selections {
  display: inline-block;
  margin-left: 20px;
}
</style>
