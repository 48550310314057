import axios from 'axios';
import { meilisearchToken, meilisearchUrl } from '@/constants';

function formatFilters(filters = {}) {
  const formatted = [];

  Object.keys(filters).forEach((facet) => {
    const facetFilter = filters[facet].map(value => `${facet}='${value.replace("'", "\\'")}'`);
    if (facetFilter.length) {
      formatted.push(facetFilter);
    }
  });

  return formatted;
}

export default {
  async search ({
    query = '',
    index = 'neustar',
    sort = 'name',
    direction = 'asc',
    facets = ['name', 'category'],
    filters = {},
    page = 1,
    perPage = 25,
  }) {
    const payload = {
      q: query,
      sort: [`${sort}:${direction}`],
      limit: perPage,
      offset: (page - 1) * perPage,
      filter: formatFilters(filters),
      facets,
    };

    const headers = {
      Authorization: `Bearer ${meilisearchToken}`,
      'Content-Type': 'application/json',
    };

    const { data } = await axios.post(`${meilisearchUrl}/indexes/${index}/search`, JSON.stringify(payload), { headers });

    return data;
  },
};
