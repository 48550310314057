<template>
  <div>
    <h3>Create Quick Select Audience</h3>
    <el-row :gutter="20">
      <el-col :span="24">
        <poi-search />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PoiSearch from '../../audience/poi/PoiSearch.vue';

export default {
  components: { PoiSearch },
};
</script>
